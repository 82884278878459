import { FC, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  TimePicker,
  message,
} from 'antd';
import { useApp } from '@/utils/useapp';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { ShipmentParams } from './QuoteForm';
import {
  calcFreightClass,
  CARRIERS,
  destinationAccesorials,
  freightClasses,
  packageingTypes,
  pickupAccesorials,
  QuoteItem,
} from '@/utils/freight';
import { debounce, intersection } from 'lodash';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import { FormInstance } from 'antd/es/form';
import moment from 'moment';
import TopCard from './TopCard';
import {
  LTLAddressType,
  LTLAddressTypeLabelMap,
  ShipmentBusinessStatus,
} from '../constants';
import { AIfillBtn } from './AIFillButton';
import { TLVendorSelect } from '@/components/TLVendorSelect';
import { TLType } from '@/components/constants';

interface Props {
  params?: ShipmentParams | null;
  model?: any;
  form?: FormInstance | null;
  loading?: boolean;
  setLoading?: (loading: boolean) => void;
}

interface City {
  id: number;
  name: string;
  state: string;
}

interface Vendor {
  id: number;
  name: string;
}

const ShipmentForm: FC<Props> = ({ params, model, loading, form = null }) => {
  const rate = params?.rate;
  const quoteFields = params?.quoteFields;

  const manualMode = !params;
  if (!form) {
    [form] = useForm();
  }

  const app = useApp();

  const [pickupCityList, setPickupCityList] = useState<City[]>([]);
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [destinationCityList, setDestinationCityList] = useState<City[]>([]);
  const pickupZipcode = useWatch('pickup_zipcode', form);
  const destinationZipcode = useWatch('destination_zipcode', form);

  const [addressTypes] = useState(
    Object.entries(LTLAddressTypeLabelMap).map(
      ([value, label]: [string, string]) => ({
        label,
        value,
      }),
    ),
  );
  const [pickupAdditions] = useState(
    pickupAccesorials.map((item) => ({
      value: item.key,
      label: item.name,
    })),
  );
  const [destinationAdditions] = useState(
    destinationAccesorials.map((item) => ({
      value: item.key,
      label: item.name,
    })),
  );
  const [packageTypes] = useState(
    packageingTypes.map((item) => ({
      value: item,
      label: item,
    })),
  );

  const [carriers] = useState(
    CARRIERS.map((item) => ({
      value: item.scac,
      label: item.name,
    })),
  );

  const [classes] = useState(
    freightClasses.map((item) => ({
      value: item.classCode,
      label: item.classCode,
    })),
  );

  const debouncePickupCityFetcher = useMemo(() => {
    const fetchPickupCityList = async (pickup_zipcode: string) => {
      try {
        const resp = await app.service.get(
          `tl/tools/citylist/${pickup_zipcode}`,
        );
        setPickupCityList(resp.data);
      } catch (e: any) {
        message.error(e.data?.message || e.data?.error);
      }
    };
    return debounce(fetchPickupCityList, 300);
  }, []);

  const debounceDestinationCityFetcher = useMemo(() => {
    const fetchDestinationCityList = async (destination_zipcode: string) => {
      try {
        const resp = await app.service.get(
          `tl/tools/citylist/${destination_zipcode}`,
        );
        setDestinationCityList(resp.data);
      } catch (e: any) {
        message.error(e.data?.message || e.data?.error);
      }
    };
    return debounce(fetchDestinationCityList, 300);
  }, []);

  const fetchVendors = async () => {
    try {
      const resp = await app.service.get('tl/ltl/vendors');
      setVendors(resp.data);
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  const handleClassSuggest = (key: number, item: QuoteItem) => {
    if (!item) {
      return;
    }
    const judge = [
      item.total_weight,
      item.width,
      item.height,
      item.length,
      item.units,
    ].every((element) => Boolean(element));
    if (!judge) {
      return;
    }
    const freightClass = calcFreightClass(
      item.total_weight,
      item.length,
      item.width,
      item.height,
      item.units,
      item.weight_unit,
      item.dimension_unit,
    );
    form.setFieldValue(['items', key, 'class'], freightClass);
  };

  const onFormValueChange = (changedValues: any, allValues: any) => {
    if (manualMode && changedValues.pickup_zipcode) {
      debouncePickupCityFetcher(changedValues.pickup_zipcode);
    }

    if (manualMode && changedValues.destination_zipcode) {
      debounceDestinationCityFetcher(changedValues.destination_zipcode);
    }

    if (changedValues.items && changedValues.items.length > 0) {
      Object.keys(changedValues.items).forEach((key) => {
        if (
          key &&
          changedValues.items[key] &&
          intersection(Object.keys(changedValues.items[key]), [
            'units',
            'pieces',
            'length',
            'width',
            'height',
            'total_weight',
            'weight_unit',
            'dimension_unit',
          ]).length
        ) {
          handleClassSuggest(parseInt(key, 10), allValues.items[key]);
        }
      });
    }

    if (changedValues.vendor_carrier_code) {
      const carrier = CARRIERS.find(
        (item) => item.scac === changedValues.vendor_carrier_code,
      );
      console.log(carrier);
      if (carrier) {
        form.setFieldsValue({
          vendor_carrier_name: carrier.name,
        });
      }
    }
  };

  useEffect(() => {
    if (pickupZipcode) {
      debouncePickupCityFetcher(pickupZipcode);
    }
  }, [pickupZipcode]);

  useEffect(() => {
    if (destinationZipcode) {
      debounceDestinationCityFetcher(destinationZipcode);
    }
  }, [destinationZipcode]);
  useEffect(() => {
    fetchVendors();
  }, []);

  useEffect(() => {
    if (manualMode) {
      form.setFieldsValue({
        ...quoteFields,
      });
    } else {
      form.setFieldsValue({
        ...quoteFields,
        quote_rate: rate?.quote_rate,
        estimated_pickup_date: quoteFields.pickup_date,
      });
    }
  }, [quoteFields, rate]);

  return (
    <>
      <Form
        layout="vertical"
        disabled={
          loading ||
          model?.business_status >= ShipmentBusinessStatus.PENDING_PICKUP
        }
        onValuesChange={onFormValueChange}
        form={form}
      >
        {rate && <TopCard rate={rate} />}
        <Space direction="vertical">
          {manualMode ? (
            <AIfillBtn
              form={form}
              setPickupCityList={setPickupCityList}
              setDestinationCityList={setDestinationCityList}
            />
          ) : (
            <AIfillBtn
              form={form}
              fields={[
                'pickup_company_name',
                'pickup_contact_name',
                'pickup_contact_email',
                'pickup_contact_phone',
                'pickup_address1',
                'pickup_address2',
                'pickup_special_request',
                'pickup_open_time',
                'pickup_close_time',
                'destination_company_name',
                'destination_contact_name',
                'destination_contact_email',
                'destination_contact_phone',
                'destination_address1',
                'destination_address2',
                'destination_special_request',
                'destination_open_time',
                'destination_close_time',
                'pickup_number',
                'dropoff_number',
                'remarks',
              ]}
              setPickupCityList={setPickupCityList}
              setDestinationCityList={setDestinationCityList}
            />
          )}
          <Row gutter={48}>
            <Col span={12}>
              <Card
                title="PICKUP"
                size="small"
                bordered={true}
                style={{
                  borderColor: '#d2ebff',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                }}
                headStyle={{
                  backgroundColor: '#d2ebff',
                  fontWeight: 'bold',
                }}
              >
                <Row gutter={32}>
                  <Col span={8}>
                    <Form.Item
                      name="pickup_company_name"
                      label="Facility Name"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter facility name." />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Space direction="horizontal">
                      <Form.Item
                        name="pickup_open_time"
                        label="Open Time"
                        initialValue={moment('08:00', 'HH:mm')}
                        rules={[{ required: true }]}
                      >
                        <TimePicker
                          format={'HH:mm'}
                          suffixIcon={null}
                          placeholder={'Pickup open time'}
                          minuteStep={15}
                          style={{
                            width: 100,
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="pickup_close_time"
                        label="Close Time"
                        initialValue={moment('21:00', 'HH:mm')}
                        rules={[{ required: true }]}
                      >
                        <TimePicker
                          format={'HH:mm'}
                          suffixIcon={null}
                          placeholder={'Pickup close time'}
                          minuteStep={15}
                          style={{
                            width: 100,
                          }}
                        />
                      </Form.Item>
                    </Space>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="pickup_contact_name"
                      label="Contact name"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter name." />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="pickup_contact_email"
                      label="Contact email"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter email." />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Space.Compact>
                      <Form.Item
                        name="pickup_contact_phone"
                        label="Contact phone"
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="Enter phone." />
                      </Form.Item>
                      <Form.Item
                        label=" "
                        name="pickup_contact_phone_ext"
                        rules={[{ required: false }]}
                      >
                        <Input placeholder="Ext." />
                      </Form.Item>
                    </Space.Compact>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Zipcode"
                      name="pickup_zipcode"
                      rules={[{ required: true }]}
                    >
                      <Input
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                        placeholder="Enter zipcode."
                      />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      label="City"
                      name="pickup_city_id"
                      rules={[{ required: true }]}
                    >
                      <Select
                        allowClear
                        placeholder="Please select city"
                        options={pickupCityList.map((item) => ({
                          value: item.id,
                          label: `${item.name}, ${item.state}`,
                        }))}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={16}>
                    <Form.Item
                      name="pickup_address1"
                      label="Address line1"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter address line1." />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="pickup_address2" label="Address line2">
                      <Input placeholder="Enter address line2." />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      name="pickup_special_request"
                      label="Special requirement"
                    >
                      <Input placeholder="Enter special requirement." />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="pickup_address_type"
                      label="Address Type"
                      initialValue={LTLAddressType.BUSSINESS}
                    >
                      <Select
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                        placeholder="Please select address type"
                        options={addressTypes}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      name="pickup_accessorials"
                      label="Accessorial services"
                    >
                      <Select
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                        mode="multiple"
                        allowClear
                        placeholder="Please select accessorial services"
                        options={pickupAdditions}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={12}>
              <Card
                title="DESTINATION"
                size="small"
                style={{
                  borderColor: '#daf8f6',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                }}
                headStyle={{ backgroundColor: '#daf8f6', fontWeight: 'bold' }}
              >
                <Row gutter={32}>
                  <Col span={8}>
                    <Form.Item
                      name="destination_company_name"
                      label="Facility name"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter facility name." />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Space direction="horizontal">
                      <Form.Item
                        name="destination_open_time"
                        label="Open Time"
                        initialValue={moment('08:00', 'HH:mm')}
                        rules={[{ required: true }]}
                      >
                        <TimePicker
                          format={'HH:mm'}
                          suffixIcon={null}
                          placeholder={'Delivery open time'}
                          minuteStep={15}
                          style={{
                            width: 100,
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="destination_close_time"
                        label="Close Time"
                        initialValue={moment('21:00', 'HH:mm')}
                        rules={[{ required: true }]}
                      >
                        <TimePicker
                          format={'HH:mm'}
                          suffixIcon={null}
                          placeholder={'Delivery close time'}
                          minuteStep={15}
                          style={{
                            width: 100,
                          }}
                        />
                      </Form.Item>
                    </Space>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="destination_contact_name"
                      label="Contact name"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter name." />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="destination_contact_email"
                      label="Contact email"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter email." />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Space.Compact>
                      <Form.Item
                        name="destination_contact_phone"
                        label="Contact phone"
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="Enter phone." />
                      </Form.Item>
                      <Form.Item
                        label=" "
                        name="destination_contact_phone_ext"
                        rules={[{ required: false }]}
                      >
                        <Input placeholder="Ext." />
                      </Form.Item>
                    </Space.Compact>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Zip"
                      name="destination_zipcode"
                      rules={[{ required: true }]}
                    >
                      <Input
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                        placeholder="Enter zipcode."
                      />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      label="City"
                      name="destination_city_id"
                      rules={[{ required: true }]}
                    >
                      <Select
                        allowClear
                        placeholder="Please select city"
                        options={destinationCityList.map((item) => ({
                          value: item.id,
                          label: `${item.name}, ${item.state}`,
                        }))}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={16}>
                    <Form.Item
                      name="destination_address1"
                      label="Address line1"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter address line1." />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="destination_address2"
                      label="Address line2"
                    >
                      <Input placeholder="Enter address line2." />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      name="destination_special_request"
                      label="Special requirement"
                    >
                      <Input placeholder="Enter special requirement." />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="destination_address_type"
                      label="Address Type"
                      initialValue={LTLAddressType.BUSSINESS}
                    >
                      <Select
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                        placeholder="Please select address type"
                        options={addressTypes}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      name="destination_accessorials"
                      label="Accessorial services"
                    >
                      <Select
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Please select accessorial services"
                        options={destinationAdditions}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Space direction="horizontal" size={16}>
                <Form.Item
                  label="Est Pickup Date"
                  name="estimated_pickup_date"
                  rules={[{ required: true }]}
                  getValueFromEvent={(onChange) =>
                    moment(onChange).format('YYYY-MM-DD')
                  }
                  getValueProps={(v) => ({ value: v ? moment(v) : null })}
                >
                  <DatePicker
                    disabled={
                      !manualMode ||
                      model?.business_status >=
                        ShipmentBusinessStatus.PENDING_PICKUP
                    }
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item
                  name="user_id"
                  label="Customer"
                  rules={[{ required: true }]}
                >
                  <UserSyncSelect
                    disabled={
                      !manualMode ||
                      model?.business_status >=
                        ShipmentBusinessStatus.PENDING_PICKUP
                    }
                    style={{ width: 160 }}
                  />
                </Form.Item>
                <Form.Item name="operator_id" label="Operator">
                  <UserSyncSelect
                    style={{ width: 160 }}
                    type="admin"
                    allowClear
                  />
                </Form.Item>
                <Form.Item name="sales_id" label="Sales">
                  <UserSyncSelect
                    style={{ width: 160 }}
                    type="admin"
                    allowClear
                  />
                </Form.Item>
                <Form.Item name="sales_support_id" label="Sales Support">
                  <UserSyncSelect
                    style={{ width: 160 }}
                    type="admin"
                    allowClear
                  />
                </Form.Item>
                <Form.Item name="quote_rate" label="Quote rate">
                  <InputNumber
                    disabled={
                      !manualMode ||
                      model?.business_status >=
                        ShipmentBusinessStatus.PENDING_PICKUP
                    }
                  />
                </Form.Item>

                {manualMode ? (
                  <>
                    <Form.Item name="vendor_rate" label="Buy rate">
                      <InputNumber min={0} max={99999.99} />
                    </Form.Item>
                    <Form.Item
                      name="sell_rate"
                      label="Sell rate"
                      rules={[{ required: true }]}
                    >
                      <InputNumber min={0} />
                    </Form.Item>
                  </>
                ) : (
                  <> </>
                )}
              </Space>
            </Col>
          </Row>
          {manualMode ? (
            <Row gutter={16}>
              <Col span={24}>
                <Space>
                  <Form.Item
                    name="vendor_id"
                    label="Vendor"
                    rules={[{ required: true }]}
                  >
                    <TLVendorSelect
                      tlType={TLType.LTL}
                      style={{ minWidth: '200px' }}
                      selected={form.getFieldValue('vendor_id')}
                      onSelect={(vendor: any) =>
                        form.setFieldValue('vendor_id', vendor?.id || null)
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="vendor_carrier_code"
                    label="Carrier"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Select carrier."
                      options={carriers}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      allowClear
                      style={{
                        width: 200,
                      }}
                    />
                  </Form.Item>
                  <Form.Item hidden name="vendor_carrier_name">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="vendor_shipment_id"
                    label="Vendor Shipment ID"
                  >
                    <Input placeholder="Enter vendor shipment id." />
                  </Form.Item>
                </Space>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <Row gutter={16}>
            <Col span={24}>
              <Space direction="horizontal" size={16}>
                <Form.Item
                  label="Customer Reference Number"
                  name="customer_reference_number"
                >
                  <Input />
                </Form.Item>
                <Form.Item label="Pickup Number" name="pickup_number">
                  <Input />
                </Form.Item>
                <Form.Item label="Dropoff Number" name="dropoff_number">
                  <Input />
                </Form.Item>
              </Space>
            </Col>
          </Row>
          <Row>
            <Col span={20}>
              <Form.Item label="Remarks" name="remarks">
                <Input.TextArea showCount maxLength={500} rows={4} />
              </Form.Item>
            </Col>
          </Row>
        </Space>
        <Form.List name="items">
          {(fields, { add, remove }) => (
            <Row gutter={8}>
              {fields.map(({ key, name, ...restField }) => (
                <Col key={key} span={24}>
                  <Space
                    size="small"
                    style={{ display: 'flex' }}
                    align={name > 0 ? 'baseline' : 'center'}
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'units']}
                      initialValue={1}
                      label={name > 0 ? '' : 'Units'}
                    >
                      <InputNumber
                        min={1}
                        placeholder="Units"
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                        style={{
                          width: '100%',
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'package_type']}
                      label={name > 0 ? '' : 'Package Type'}
                      initialValue={'Pallet'}
                      rules={[{ required: true }]}
                    >
                      <Select
                        placeholder="Select package type."
                        options={packageTypes}
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                        style={{
                          width: 200,
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'pieces']}
                      initialValue={1}
                      label={name > 0 ? '' : 'Pieces'}
                    >
                      <InputNumber
                        min={1}
                        placeholder="Pieces"
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                        style={{
                          width: '100%',
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'length']}
                      initialValue={1}
                      label={name > 0 ? '' : 'Length'}
                      rules={[{ required: true, message: 'Missing length' }]}
                    >
                      <InputNumber
                        min={1}
                        placeholder="Length"
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                        style={{
                          width: '100%',
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'width']}
                      initialValue={1}
                      label={name > 0 ? '' : 'Width'}
                      rules={[{ required: true, message: 'Missing width' }]}
                    >
                      <InputNumber
                        min={1}
                        placeholder="Width"
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                        style={{
                          width: '100%',
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'height']}
                      initialValue={1}
                      label={name > 0 ? '' : 'Height'}
                      rules={[{ required: true, message: 'Missing height' }]}
                    >
                      <InputNumber
                        min={1}
                        placeholder="Height"
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                        addonAfter={
                          <Form.Item
                            {...restField}
                            name={[name, 'dimension_unit']}
                            initialValue={'in'}
                            noStyle
                            rules={[{ required: true }]}
                          >
                            <Select
                              style={{
                                width: 64,
                              }}
                              options={[
                                {
                                  value: 'in',
                                  label: 'in',
                                },
                                {
                                  value: 'cm',
                                  label: 'cm',
                                },
                              ]}
                              disabled={
                                !manualMode ||
                                model?.business_status >=
                                  ShipmentBusinessStatus.PENDING_PICKUP
                              }
                            />
                          </Form.Item>
                        }
                        style={{
                          width: '100%',
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, 'total_weight']}
                      initialValue={'1'}
                      label={name > 0 ? '' : 'Total weight'}
                      rules={[
                        { required: true, message: 'Missing total weight' },
                      ]}
                    >
                      <InputNumber
                        min={1}
                        placeholder="Total weight"
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                        addonAfter={
                          <Form.Item
                            {...restField}
                            name={[name, 'weight_unit']}
                            initialValue={'lbs'}
                            rules={[{ required: true }]}
                            noStyle
                          >
                            <Select
                              style={{
                                width: 64,
                              }}
                              options={[
                                {
                                  value: 'kg',
                                  label: 'kg',
                                },
                                {
                                  value: 'lbs',
                                  label: 'lbs',
                                },
                              ]}
                              disabled={
                                !manualMode ||
                                model?.business_status >=
                                  ShipmentBusinessStatus.PENDING_PICKUP
                              }
                            />
                          </Form.Item>
                        }
                        style={{
                          width: '100%',
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, 'class']}
                      initialValue={'50'}
                      label={name > 0 ? '' : 'Class'}
                      rules={[{ required: true }]}
                      style={{
                        width: 200,
                      }}
                    >
                      <Select
                        placeholder="Select class."
                        options={classes}
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'description']}
                      label={name > 0 ? '' : 'Description'}
                      rules={[{ required: true }]}
                      style={{
                        width: 200,
                      }}
                    >
                      <Input
                        placeholder="Enter description."
                        disabled={
                          !manualMode ||
                          model?.business_status >=
                            ShipmentBusinessStatus.PENDING_PICKUP
                        }
                      />
                    </Form.Item>
                    {manualMode && (
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    )}
                  </Space>
                </Col>
              ))}
              {manualMode && (
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add item
                  </Button>
                </Form.Item>
              )}
            </Row>
          )}
        </Form.List>
        <Divider />
      </Form>
    </>
  );
};

export default ShipmentForm;

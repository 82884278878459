export const KEY_EXPIRED_RATE_INVISIBLE = 'expired_rate_invisible';
export const KEY_COMPANY = 'company';

export const EMAIL_DOC = 'doc@drayeasy.com';
export const EMAIL_AR = 'ar@drayeasy.com';
export const EMAIL_AP = 'ap@drayeasy.com';
export const EMAIL_OP = 'op@drayeasy.com';
export const EMAIL_GZHOU = 'gzhou@drayeasy.com';
export const EMAIL_JACY = 'jacy.y@drayeasy.com';
export const EMAIL_TL = 'tl@drayeasy.com';

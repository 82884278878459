import React, { useMemo } from 'react';
import {
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
  Row,
  Col,
  Switch,
  Divider,
  DatePicker,
} from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  LockOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { UserSyncSelect } from '@/components/UserSyncSelect';

import { startCase, camelCase } from 'lodash';
import { CompanySyncSelect } from '@/components/CompanySyncSelect';
import { ORDER_SALES_TYPE_MAP } from '@/pages/orders/components/data';
import { Documents } from '@/components/Upload/Documents';
import { AdditionalNotificationEmails } from '../AdditionalNotificationEmails';
interface Props {
  form: FormInstance;
  onSubmit: () => void;
}
const UNIT_OPTIONS = [
  { label: '1 Day', value: 86400 },
  { label: '1 Week', value: 604800 },
  { label: '2 Week', value: 1209600 },
  { label: '1 Month', value: 2592000 },
];
const RATE_RULE_TYPES = [
  { label: 'fix', value: 'fix' },
  { label: 'percentage', value: 'percentage' },
];

const DEDICATED_TYPE_VALUE = 2;

export const TYPES = [
  { label: 'Normal', value: 0 },
  { label: 'Insider', value: 1 },
  { label: 'Dedicated', value: DEDICATED_TYPE_VALUE },
  { label: 'Agent', value: 4 },
];

const SALES_TYPE_OPTIONS = [
  ...Object.keys(ORDER_SALES_TYPE_MAP).map((t: string) => ({
    value: +t,
    text:
      ORDER_SALES_TYPE_MAP[(t as unknown) as keyof typeof ORDER_SALES_TYPE_MAP],
  })),
];

export const UserForm: React.FC<Props> = ({ form, onSubmit }) => {
  const getRateRuleLabel = (name: string) => {
    return startCase(camelCase(name));
  };

  const terms = Form.useWatch(['bill_to', 'terms'], form);

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onSubmit}
      initialValues={{ type: DEDICATED_TYPE_VALUE, status: 2 }}
    >
      <Form.Item name="id" noStyle>
        <Input hidden />
      </Form.Item>
      <Row>
        <Col span={11}>
          <h5>Basic Information</h5>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please Input Name' }]}
          >
            <Input id="user_form_name" placeholder="Enter name" />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                label="Company"
                name="company_id"
                rules={[{ required: true, message: 'Please Enter Company' }]}
              >
                <CompanySyncSelect
                  selected={getFieldValue('company_id')}
                  onSelect={(company_id: any) => {
                    setFieldsValue({
                      company_id,
                    });
                  }}
                  editable={true}
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                label="Bill To"
                name="bill_to_id"
                rules={[{ required: true, message: 'Please Enter Bill To' }]}
              >
                <CompanySyncSelect
                  selected={getFieldValue('bill_to_id')}
                  onSelect={(bill_to_id: any) => {
                    setFieldsValue({
                      bill_to_id,
                    });
                  }}
                  editable={true}
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please Input Email' }]}
          >
            <Input id="user_form_email" placeholder="Enter email" />
          </Form.Item>
          <AdditionalNotificationEmails form={form} />
          <Form.Item label="Agent" name="agent_id">
            <UserSyncSelect type="user" />
          </Form.Item>
          <Form.Item
            label="Status"
            name="status"
            tooltip={{
              icon: <InfoCircleOutlined />,
              title: (
                <div>
                  <Row>Active: View 3 Rates without trucker detail.</Row>
                  <Row>Enable: View 50 Rates without trucker detail.</Row>
                </div>
              ),
            }}
            rules={[{ required: true, message: 'Please Select Status' }]}
          >
            <Select id="user_form_status">
              <Select.Option value={0}>PENDING</Select.Option>
              <Select.Option value={1}>ACTIVE</Select.Option>
              <Select.Option value={2}>ENABLED</Select.Option>
              <Select.Option value={4}>VERIFYING</Select.Option>
              <Select.Option value={-1}>DISABLED</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Type"
            name="type"
            tooltip={{
              icon: <InfoCircleOutlined />,
              title: (
                <div>
                  <Row>
                    Insider: View all rates with trucker contact and trucker
                    possible charges.
                  </Row>
                </div>
              ),
            }}
            rules={[{ required: true, message: 'Please Select Type' }]}
          >
            <Select>
              {TYPES.map((type) => (
                <Select.Option key={type.value} value={type.value}>
                  {type.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="trial_ends_at" noStyle>
            <Input hidden />
          </Form.Item>
          <Form.Item
            label={
              <>
                Trial Ends At{' '}
                <small className="ml-sm text-gray">
                  {' '}
                  (No date means no expiration)
                </small>
              </>
            }
            shouldUpdate
          >
            {({ getFieldValue, setFieldValue }) => {
              const time = getFieldValue('trial_ends_at');
              return (
                <DatePicker
                  showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  showToday
                  style={{ width: '100%' }}
                  format="YYYY-MM-DD HH:mm:ss"
                  value={time ? moment(time) : null}
                  onChange={(date, dateString) => {
                    setFieldValue('trial_ends_at', dateString);
                  }}
                />
              );
            }}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldValue }) => (
              <>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: getFieldValue('id') ? false : true,
                      message: 'Please Input password',
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Enter password"
                    prefix={<LockOutlined />}
                  />
                </Form.Item>
                <Form.Item
                  name="password_confirmation"
                  label="Password Confirmation"
                  rules={[
                    {
                      required: getFieldValue('id') ? false : true,
                      message: 'Please Input password confirmation',
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Enter password confirmation"
                    prefix={<LockOutlined />}
                  />
                </Form.Item>
              </>
            )}
          </Form.Item>
          <Form.Item name="memo" label="Memo">
            <Input.TextArea
              value={form.getFieldValue('memo')}
              rows={4}
              placeholder="Enter memo"
            />
          </Form.Item>
        </Col>
        {/* <Divider type="vertical" /> */}
        <Col span={1} />
        <Col span={11}>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) =>
              getFieldValue('id') && (
                <Documents
                  title="Documents"
                  target="user_docs"
                  targetId={getFieldValue('id')}
                />
              )
            }
          </Form.Item>

          <Divider />

          <Form.Item label="Default Sales" name="sales_id">
            <UserSyncSelect type="admin" />
          </Form.Item>
          <Form.Item
            label="Default Drayage Sales Support"
            name="sales_support_id"
          >
            <UserSyncSelect type="admin" />
          </Form.Item>

          <Form.Item
            label="Default LTL Sales Support"
            name="ltl_sales_support_id"
          >
            <UserSyncSelect type="admin" />
          </Form.Item>

          <Form.Item
            label="Default FTL Sales Support"
            name="ftl_sales_support_id"
          >
            <UserSyncSelect type="admin" />
          </Form.Item>

          <Form.Item label="Default Sales Type" name="sales_type_id">
            <Select>
              {SALES_TYPE_OPTIONS.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Divider />
          <Row gutter={8}>
            <Col md={4}>
              <h5>Can Order Frontend</h5>
              <Form.Item
                label=""
                name="can_order"
                valuePropName="checked"
                rules={[{ required: true }]}
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={form.getFieldValue('can_order')}
                />
              </Form.Item>
            </Col>
            <Col md={4}>
              <h5>Enabled Estimate Invoice</h5>
              <Form.Item
                label=""
                name="enabled_estimate_invoice"
                valuePropName="checked"
                rules={[{ required: false }]}
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={form.getFieldValue('enabled_estimate_invoice')}
                />
              </Form.Item>
            </Col>
            <Col md={4}>
              <h5>Multi Devices Login</h5>
              <Form.Item
                label=""
                name="is_multi_devices_login"
                valuePropName="checked"
                rules={[{ required: false }]}
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={form.getFieldValue('is_multi_devices_login')}
                />
              </Form.Item>
            </Col>
            <Col md={4}>
              <h5>Is Prepayable Account</h5>
              <Form.Item
                label=""
                name="is_prepayable"
                valuePropName="checked"
                rules={[{ required: false }]}
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={form.getFieldValue('is_prepayable')}
                />
              </Form.Item>
            </Col>
            <Col md={4}>
              <h5>Is LTL Enabled</h5>
              <Form.Item
                label=""
                name="ltl_enabled"
                valuePropName="checked"
                rules={[{ required: false }]}
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={form.getFieldValue('ltl_enabled"')}
                />
              </Form.Item>
            </Col>
            <Col md={4}>
              <h5>Is FTL Enabled</h5>
              <Form.Item
                label=""
                name="ftl_enabled"
                valuePropName="checked"
                rules={[{ required: false }]}
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={form.getFieldValue('ftl_enabled"')}
                />
              </Form.Item>
            </Col>
          </Row>

          <h5>Limit</h5>
          <Row>
            <Col span={11}>
              <Form.Item
                label="Rate Limit"
                name="rate_search_limit"
                rules={[{ required: true, message: 'Please Input rate limit' }]}
              >
                <InputNumber
                  min={0}
                  placeholder="Enter rate limit"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={1} />
            <Col span={11}>
              <Form.Item
                label="Unit"
                name="rate_search_limit_decay_seconds"
                rules={[
                  {
                    required: true,
                    message: 'Please Select rate search limit unit',
                  },
                ]}
              >
                <Select>
                  {UNIT_OPTIONS.map(
                    (option: { value: number; label: string }) => (
                      <Select.Option
                        key={option.value + option.label}
                        value={option.value}
                      >
                        {option.label}
                      </Select.Option>
                    ),
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={11}>
              <Form.Item
                label="Requested Rate Create Limit"
                name="requested_rate_create_limit"
                rules={[
                  {
                    required: true,
                    message: 'Please Input requested rate create limit',
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  placeholder="Enter requested rate create limit"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={1} />
            <Col span={11}>
              <Form.Item
                label="Unit"
                name="requested_rate_create_limit_decay_seconds"
                rules={[
                  {
                    required: true,
                    message: 'Please Select requested rate create limit unit',
                  },
                ]}
              >
                <Select>
                  {UNIT_OPTIONS.map(
                    (option: { value: number; label: string }) => (
                      <Select.Option
                        key={option.value + option.label}
                        value={option.value}
                      >
                        {option.label}
                      </Select.Option>
                    ),
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row></Row>
          <Divider />
          <h5>Rate Makup</h5>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => (
              <Form.List name="rate_rules">
                {(rate_rules) => {
                  rate_rules.forEach((rate_rule: any, index) => {
                    rate_rule.record = getFieldValue('rate_rules')[index];
                  });
                  return (
                    <>
                      {rate_rules.map((rate_rule: any, index) => (
                        <Row key={rate_rule.key}>
                          <Col span={11}>
                            <Form.Item
                              name={[rate_rule.name, 'type']}
                              label="Type"
                            >
                              <Select>
                                {RATE_RULE_TYPES.map((option) => (
                                  <Select.Option
                                    key={rate_rule.record.name + option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={1} />
                          <Col span={11}>
                            <Form.Item
                              name={[rate_rule.name, 'value']}
                              label={getRateRuleLabel(rate_rule.record.name)}
                            >
                              {rate_rule.record.type === 'percentage' ? (
                                <InputNumber
                                  placeholder={
                                    'Enter ' +
                                    getRateRuleLabel(rate_rule.record.name)
                                  }
                                  min={0}
                                  max={100}
                                  formatter={(value) => `${value}%`}
                                  parser={(value: any) =>
                                    value!.replace('%', '')
                                  }
                                  style={{ width: '100%' }}
                                />
                              ) : (
                                <InputNumber
                                  placeholder={
                                    'Enter ' +
                                    getRateRuleLabel(rate_rule.record.name)
                                  }
                                  min={0}
                                  style={{ width: '100%' }}
                                />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      ))}
                    </>
                  );
                }}
              </Form.List>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
